window._ = require('lodash');

try {
    require('bootstrap');
} catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

import Echo from 'laravel-echo';

window.Pusher = require('pusher-js');
// Development
// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: 'b43fc4ea6bf5aaf8145c',
//     cluster: 'ap1',
//     forceTLS: true, 
//     encrypted:false
// });

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: '11279e78c1c520fdca40',
    cluster: 'ap1',
    forceTLS: true, 
    encrypted:false
});
